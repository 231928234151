<template>
  <el-main>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>共享额度</span>
      </div>
      <el-row>
        <el-col :span="24">
          <el-form :inline="true" :model="form">
            <el-form-item label="部门">
              <el-select v-model="form.department_id" placeholder="全部" clearable>
                <el-option v-for="item in options.department" :key="item.id" :label="item.name"
                           :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="search">搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-table
          :data="tableData"
          style="width: 100%">
        <el-table-column
            prop="platform_name"
            label="平台"
        >
        </el-table-column>
        <el-table-column
            prop="type_cn"
            label="任务类型"
        >
        </el-table-column>
        <el-table-column
            prop="remain"
            label="可用额度"
        >
        </el-table-column>
        <el-table-column
            prop="max_task_count"
            label="最大限额">
        </el-table-column>
      </el-table>
    </el-card>
    <el-card class="box-card" style="margin-top: 25px">
      <div slot="header" class="clearfix">
        <span>独享额度</span>
      </div>
      <el-row>
        <el-col :span="24">
          <el-form :inline="true" :model="form">
            <el-form-item label="部门">
              <el-select v-model="form.user_id" placeholder="全部" clearable>
                <el-option v-for="item in options.vip" :key="item.id" :label="item.name"
                           :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchVip">搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-table
          :data="tableDataVip"
          style="width: 100%">
        <el-table-column
            prop="platform_name"
            label="平台"
        >
        </el-table-column>
        <el-table-column
            prop="type_cn"
            label="任务类型"
        >
        </el-table-column>
        <el-table-column
            prop="remain"
            label="可用额度"
        >
        </el-table-column>
        <el-table-column
            prop="max_task_count"
            label="最大限额">
        </el-table-column>
      </el-table>
    </el-card>
  </el-main>
</template>

<script>
export default {
  name: "LimitList",
  created() {
    let me = this;
    Promise.all([me.axios.get('/api/platform/list')
      , me.axios.get('/api/department/list'), me.axios.get('/api/user/vips')
    ])
        .then(([platforms, departments, vips]) => {
          platforms.forEach(platform => {
            me.map.platform['id_' + platform.id] = platform.name;
          })
          departments.forEach(department => {
            me.map.department['id_' + department.id] = department.name;
            me.options.department.push(department);
          })
          vips.forEach(vip => {
            me.options.vip.push(vip);
          });

        })
  },
  data() {
    return {
      options: {
        department: [],
        vip: []
      },
      map: {
        department: {},
        platform: {},
        type: {
          'id_1': '关键词',
          'id_2': '评论'
        }
      },
      form: {
        department_id: null,
        user_id:null
      },
      rawData: [],
      rawDataVip: [],
    }
  },
  computed: {
    tableData() {
      let me = this;
      if (!me.rawData) {
        return [];
      }
      return me.rawData.map(val => {
        val['platform_name'] = me.map.platform['id_' + val['platform_id']];
        val['type_cn'] = me.map.type['id_' + val['task_type']];
        return val;
      });
    },
    tableDataVip() {
      let me = this;
      if (!me.rawDataVip) {
        return [];
      }
      return me.rawDataVip.map(val => {
        val['platform_name'] = me.map.platform['id_' + val['platform_id']];
        val['type_cn'] = me.map.type['id_' + val['task_type']];
        return val;
      });
    },
  },
  methods: {
    search() {
      let me = this;
      if (me.form.department_id == null) {
        me.$message.error("请选择一个部门");
        return;
      }
      this.axios.post('/api/department/getLimits', {department_id: me.form.department_id}).then(res => {
        me.rawData = res;
      });
    },
    searchVip() {
      let me = this;
      if (me.form.user_id == null) {
        me.$message.error("请选择一个VIP");
        return;
      }
      this.axios.post('/api/user/limits', {user_id: me.form.user_id}).then(res => {
        me.rawDataVip = res;
      });
    }
  }
}
</script>

<style scoped>

</style>